.container {
  text-align: center;
  left: 0;
  right: 0;
}
.container #logo {
  padding: 20px;
}
.container #video {
  margin-bottom: 20px;
}
.container video {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.container p {
  font-size: 20px;
  line-height: 28px;
  color: #898b8f;
  margin: 25px 25px 0 25px;
  text-align: left !important;
}
.container ion-button {
  width: 80%;
  --background: #ed1c24;
  margin-bottom: 30px;
}
ion-thumbnail {
  width: 80px;
  height: auto;
  margin-top: 10px;
}
ion-item {
  padding: 10px;
}
ion-label {
  color: red !important;
}
h1 {
  color: #0019d4;
}
h1:nth-of-type(2) {
  margin-bottom: 0 !important;
}
h2 {
  color: #ed1c24;
}
h3 {
  font-size: 20px !important;
  /* color: #56b839; */
  color: #4dbae8;
  margin: 0 !important;
}
h5 {
  color: #898b8f;
  margin: 0 !important;
}
